import { useEffect, useState } from "react";
import { useStyles } from "./FamilyRegistrationFormStyle";
import { useParams, useLocation } from "react-router-dom";
import { useNavigate } from "react-router";

import clsx from "clsx";
import ActionInput from "components/ActionInput/ActionInput";
import ActionButton2 from "components/ActionButton2/ActionButton2";
import { ApiUsersInvitation } from "Apis/ApiUsersInvitation";
import { RES_STATUS_OK, FAMILY_REGISTRATION_FINISH } from "config/constant";


export const FamilyRegistrationForm = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const [apiWaitStatus, setApiWaitStatus] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [resultMsg, setResultMsg] = useState("");
  const [personName, setPersonName] = useState("");
  const [personEmail, setPersonEmail] = useState("");
  const [password, setPassword] = useState("");
  const [conPassword, setConPassword] = useState("");
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get('token');

  const handleFamilyRegistration = async () => {
    if (personName.length == 0) {
      setErrMsg("氏名が入力されていません。");
      return;
    }

    if (personEmail.length == 0) {
      setErrMsg("メールアドレスが入力されていません。");
      return;
    } else {
      if (!/^[^@]+@[^@.]+.[^@]+$/.test(personEmail)) {
        setErrMsg("メールアドレスの形式が正しくありません。");
        return;
      }
    }

    if (password.length < 8) {
      setErrMsg("パスワードは8文字以上入力してください。");
      return;
    } else {
      if (!(password.match(/([a-zA-Z])/) && password.match(/([0-9])/))) {
        setErrMsg("パスワードは英数字混合にしてください。");
        return;
      } else {
        if (password !== conPassword) {
          setErrMsg("パスワードとパスワード（再入力）が一致しません。");
          return;
        }
      }
    }

    setApiWaitStatus(true);
    const data = {
      "name": personName,
      "email": personEmail,
      "password": password,
      "token": token
    }

    const response = await ApiUsersInvitation(data);
    if (response["result-status"] === RES_STATUS_OK) {
      setErrMsg("");
      setResultMsg(response["message"]);
      navigate(FAMILY_REGISTRATION_FINISH);
    } else {
      setResultMsg("");
      setErrMsg(response["message"]);
    }
    setApiWaitStatus(false);
  };

  return (
    <div className={classes.formWrapper}>
      <div className={classes.loaderWrapper}>
        <div className={classes.title}>家族登録</div>
        <div className={classes.mainContainer}>
          <div>
            招待されたご家族の方ご自身の情報をご記載ください。
          </div>
          <div className={clsx(classes.message, classes.errorMessage)}>
            {errMsg}
          </div>
          <div className={clsx(classes.message, classes.resultMessage)}>
            {resultMsg}
          </div>
          <div className={classes.form}>
            <div className={classes.formTitle}>氏名<span className={classes.requiredMark}>*</span></div>
            <ActionInput
              value={personName}
              action={(e) => setPersonName(e)}
              placeholder="ふくり くらら"
              className={classes.formInput}
            />
          </div>
          <div className={classes.form}>
            <div className={classes.formTitle}>メールアドレス<span className={classes.requiredMark}>*</span>
            </div>
            <ActionInput
              value={personEmail}
              action={(e) => setPersonEmail(e)}
              placeholder="example@shisaku-pad.co.jp"
              className={classes.formInput}
            />
          </div>
          <div className={classes.form}>
            <div className={classes.formTitle}>パスワード<span className={classes.requiredMark}>*</span></div>
            <ActionInput
              value={password}
              action={(e) => setPassword(e)}
              placeholder="************"
              className={classes.formInput}
            />
          </div>
          <div className={classes.form}>
            <div className={classes.formTitle}>パスワード（確認）<span className={classes.requiredMark}>*</span></div>
            <ActionInput
              value={conPassword}
              action={(e) => setConPassword(e)}
              placeholder="************"
              className={classes.formInput}
            />
          </div>
        </div>
        <div className={classes.btnContainer}>
          <ActionButton2
            type="dark"
            disable={apiWaitStatus}
            action={handleFamilyRegistration}
            loading={apiWaitStatus}
            content={apiWaitStatus ? "処理中" : "登録する >"}
            className={clsx(
              classes.sendBtn,
              apiWaitStatus && classes.loading
            )}
          />
        </div>
      </div>
    </div >
  );
};
