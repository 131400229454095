import { REQUEST_API_URL } from "../config/constant";

export async function ApiResetPassword(token: any, password:string ){
    const response: any = await fetch(REQUEST_API_URL + '/reset_password', {
        method: 'PUT',
        mode: 'cors',
        cache: 'no-store',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        },
        redirect: 'error',
        referrerPolicy: 'no-referrer-when-downgrade',
        body: JSON.stringify({token:token, password:password})
    })
        
    return response.json();
}