import { REQUEST_API_URL } from "../config/constant";

export function ApiLogout(){
    return fetch(REQUEST_API_URL + '/logout', {
        method: 'GET',
        mode: 'cors',
        cache: 'no-store',
        credentials: 'include',
        redirect: 'error',
        referrerPolicy: 'no-referrer-when-downgrade',
    })
        .then(res => res.json())
        .then(data => {
        })
}