import { useStyles } from "./FamilyRegistrationFinishStyle";
import googleButtonImage from "../../assets/images/btn-google.png";
import iosButtonImage from "../../assets/images/btn-ios.png";
import { ANDROID_NAVITVE_LINL, IOS_NAVITVE_LINL } from "config/constant";

export const FamilyRegistrationFinish = () => {
  const classes = useStyles();

  return (
    <div className={classes.formWrapper}>
      <div className={classes.loaderWrapper}>
        <div className={classes.title}>登録完了</div>
        <div className={classes.textContainer}>
          <p>
            家族登録が完了しました。<br />
            さっそくアプリをインストールしてご利用ください！
            <br />
            <br />
            アプリのインストールは、登録完了メールからも確認できます。
          </p>
          <div className={classes.installContainer}>
            <div className={classes.borderLine}></div>
            <p className={classes.installText}>
              アプリインストールはこちらから
            </p>
            <div className={classes.nativeLink}>
              <a
                href={IOS_NAVITVE_LINL}
                className={classes.linkBtn}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={iosButtonImage}
                  className={classes.nativeButtonImage}
                  alt="google-app-link"
                />
              </a>
              <a
                href={ANDROID_NAVITVE_LINL}
                className={classes.linkBtn}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={googleButtonImage}
                  className={classes.nativeButtonImage}
                  alt="google-app-link"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
};
