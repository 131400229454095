import { ApiAccessLogs } from "Apis/ApiAccessLogs";

const fullNameOrFamilyName = (
  fullName: any,
  firstName: any,
  lastName: any
): string => {
  return !!fullName ? fullName : `${lastName} ${firstName}`;
};

// MEMO: class化してもいい
const recordAccessLog = async (userId: string, clientId: string) => {
  const params = {
    user_id: userId,
    client_id: clientId,
    devise: "Web",
  };
  await ApiAccessLogs(params);
};

export { fullNameOrFamilyName, recordAccessLog };
