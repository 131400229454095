import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Opacity } from "@material-ui/icons";
import { initial } from "lodash";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    displayNone: {
      display: "none",
    },
    loaderWrapper: {
      position: "fixed",
      zIndex: 99998,
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(0, 0, 0, 0.3)",
      overflowY: "scroll",
      padding: "16px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontFamily: "Noto Sans JP",
    },
    modalRoot: {
      padding: "16px 16px 32px 16px",
      boxSizing: "border-box",
      width: "620px",
      height: "fit-content",
      background: "#fff",
      boxShadow: "0px 0px 30px rgba(55, 55, 79, 0.05)",
      borderRadius: "10px",
      textAlign: "center",
      margin: "auto",
      [theme.breakpoints.down(769)]: {
        minWidth: "100% !important",
        width: "100% !important",
      },
    },
    title: {
      fontStyle: "normal",
      //fontFamily: "Montserrat",
      fontFamily: "Noto Sans JP",
      fontWeight: 600,
      fontSize: "20px",
      lineHeight: "50px",
      color: "#55727e",
    },
    mainContainer: {
      //padding: "50px 50px 0 50px",
      padding: "18px 50px 0 50px",
      [theme.breakpoints.down(540)]: {
        padding: 0,
      },
    },
    btnContainer: {
      //marginTop: "50px",
      marginTop: "36px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      [theme.breakpoints.down(570)]: {
        display: "block",
        width: "100%",
      },
    },
    cancelBtn: {
      width: "100% !important",
      marginBottom: "20px",
      marginRight: "15px",
    },
    confirmBtn: {
      marginBottom: "20px",
      width: "100% !important",
      [theme.breakpoints.down(570)]: {
        marginLeft: "0px",
      },
    },
    backBtn: {
      width: "70% !important",
      "&:hover": {
        opacity: 0.7,
      },
      [theme.breakpoints.down(540)]: {
        width: "100% !important",
        padding: "12px 24px",
        fontSize: "12px",
      },
      [theme.breakpoints.down(400)]: {
        width: "100% !important",
        padding: "8px 10px",
        fontSize: "10px",
      },
    },
    sendBtn: {
      "& div": {
        right: "30px",
      },
      width: "100% !important",
      "&:hover": {
        opacity: 0.7,
      },
      [theme.breakpoints.down(540)]: {
        padding: "12px 24px",
        fontSize: "12px",
        margin: "initial !important",
      },
      [theme.breakpoints.down(400)]: {
        fontSize: "10px",
      },
    },
    loading: {
      opacity: 0.7,
    },
    form: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      marginBottom: "10px",
    },
    formTitle: {
      minWidth: "100px",
      marginRight: "5px",
      color: "#000",
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "29px",
      textAlign: "left",
    },
    message: {
      minWidth: "100px",
      //margin: "0 0 30px 0",
      margin: "15px 0 15px 0",
      fontWeight: 800,
      fontSize: "14px",
      lineHeight: "29px",
      textAlign: "left",
    },
    errorMessage: {
      color: "red",
    },

    resultMessage: {
      color: "green",
    },
    formInput: {
      /* color: "#000",
      fontFamily: "Noto Sans JP",//20230111
      fontWeight: 400,
      fontSize: "18px",
      lineHeight: "29px",
      textAlign: "center", */
      "&:focus-within": {
        outline: "2px solid #6AC6C6",
      },
    },

    resetPassword: {
      display: "inline-block",
      color: "#333333",
      fontSize: "14px",
      lineHeight: 1.4,
      margin: "14px 0 36px 0",
      cursor: "pointer",
      "&:hover": {
        opacity: 0.7,
      },
    },
    resetPasswordDiscription: {
      cursor: "auto",
      padding: "10px 12px",
      backgroundColor: "#fee5de",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: "#ff4e36",
      borderRadius: "12px",
      "&:hover": {
        opacity: 1,
      },
    },
    descriptionBox: {
      textAlign: "left",
      fontWeight: 700,
      marginLeft: "12px",
      lineHeight: "1.5",
    },
    emailChangeForm: {
      paddingTop: "24px",
      width: "80%",
      margin: "0 auto",
    },
  })
);
