import { createStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { LOGO_MAP, MOBILE_MENU_THRESHOLD_WIDTH } from "../../config/constant";
export const SidebarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: "fixed",
      textAlign: "center",
      width: "300px",
      boxSizing: "border-box",
      background: "#fff",
      padding: "48px 12px 48px 12px",
      height: "100%",
      zIndex: 2,
      transition: ".3s cubic-bezier(.4,.4,0,1)",
      overflowY: "scroll",
      [theme.breakpoints.down(MOBILE_MENU_THRESHOLD_WIDTH)]: {
        width: "100%",
        backgroundColor: "white",
        position: "fixed",
        padding: "0px",
        bottom: "0px",
        height: "91px",
        zIndex: 30,
        boxShadow: "0px -4px 5px rgb(0 0 0 / 3%)",
        /*
        top:"100%",
        marginTop:"-94px",
         */
      },
    },
    logoA: {
      justifyContent: "center",
      [theme.breakpoints.down(MOBILE_MENU_THRESHOLD_WIDTH)]: {
        display: "none",
      },
    },
    logoImg: {
      width: "167px",
      //height: "44px",
      cursor: "pointer",
      "&:hover": {
        opacity: 0.5,
      },
      [theme.breakpoints.down(400)]: {
        width: "100px",
        height: "25px",
      },
    },
    avatarRoot: {
      marginTop: "24px",
      //marginBottom: "72px",
      marginBottom: "24px",
      textAlign: "center",
      cursor: "pointer",

      alignItems: "center",
      flexDirection: "column",
      "&:hover": {
        opacity: 0.5,
      },
      [theme.breakpoints.down(MOBILE_MENU_THRESHOLD_WIDTH)]: {
        display: "none",
      },
    },
    avatarImg: {
      width: "64px",
      height: "64px",
      borderRadius: "50px",
      marginBottom: "12px",
    },
    avatarName: {
      color: "#333333",
      //fontFamily: "ヒラギノ角ゴ W6 JIS2004",
      fontFamily: "Noto Sans JP",
      fontSize: "12px",
      fontWeight: 600,
      letterSpacing: "0.15em",
      lineHeight: "1.4",
      height: "16px",
    },
    avatarJob: {
      color: "#9ab0bc",
      //fontFamily: "ヒラギノ角ゴ W6 JIS2004",
      fontFamily: "Noto Sans JP",
      fontSize: "12px",
      fontWeight: 600,
      letterSpacing: "0.15em",
      lineHeight: "1.4",
      height: "16px",
      marginTop: "12px",
    },
    logoutBtn: {
      color: "#9ab0bc",
      //fontFamily: "ヒラギノ角ゴ W6 JIS2004",
      fontFamily: "Noto Sans JP",
      fontSize: "14px",
      fontWeight: 600,
      height: "16px",
      margin: "24px 0 24px",
      letterSpacing: "0.05em",
      lineHeight: "1.4",
      cursor: "pointer",
      [theme.breakpoints.down(540)]: {
        fontSize: "12px",
      },
      [theme.breakpoints.down(MOBILE_MENU_THRESHOLD_WIDTH)]: {
        display: "none",
      },
      "&:hover": {
        opacity: 0.5,
      },
    },
    sidebarContent: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      [theme.breakpoints.down(MOBILE_MENU_THRESHOLD_WIDTH)]: {
        flexDirection: "row",
      },
    },
    sidebarItem: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "71px",
      borderBottom: "1px solid #eeeeee",
      transition: ".3s cubic-bezier(.4,.4,0,1)",
      margin: "0 12px",
      cursor: "pointer",
      "&:hover": {
        opacity: 0.5,
      },
      position: "relative",
      [theme.breakpoints.down(MOBILE_MENU_THRESHOLD_WIDTH)]: {
        margin: 0,
        borderBottom: "none",
        //display:"none",
      },
    },
    sidebarItemMobileCaption: {
      display: "none",
      [theme.breakpoints.down(MOBILE_MENU_THRESHOLD_WIDTH)]: {
        display: "block",
        color: "#777777",
        position: "absolute",
        bottom: "16px",
        fontSize: "9px",
      },
    },
    itemImg: {
      background: "rgba(0,0,0,0.0)",
      borderRadius: "12px",
      marginRight: "12px",
      width: "40px",
      height: "40px",
      minWidth: "40px",
      backgroundSize: "cover",
      [theme.breakpoints.down(500)]: {
        margin: 0,
      },
      [theme.breakpoints.down(MOBILE_MENU_THRESHOLD_WIDTH)]: {
        width: "24px",
        height: "24px",
        minWidth: "24px",
        margin: "auto",
        position: "absolute",
        bottom: "31px",
      },
    },
    disable_pc: {
      [theme.breakpoints.up(MOBILE_MENU_THRESHOLD_WIDTH + 1)]: {
        display: "none",
      },
    },
    disable_mobile: {
      [theme.breakpoints.down(MOBILE_MENU_THRESHOLD_WIDTH)]: {
        display: "none",
      },
    },
    itemContent: {
      color: "#9ab0bc",
      //fontFamily: "ヒラギノ角ゴ W6 JIS2004",
      fontFamily: "Noto Sans JP",
      fontSize: "16px",
      fontWeight: 600,
      height: "auto",
      letterSpacing: "0.15em",
      lineHeight: "1.4",
      padding: "0 12px",
      minWidth: "171.2px",
      maxWidth: "171.2px",
      boxSizing: "border-box",
      textAlign: "start",
      /* [theme.breakpoints.down(500)]: {
        minWidth: "133px",
      }, */
      //[theme.breakpoints.down(400)]: {
      [theme.breakpoints.down(540)]: {
        //minWidth: "80px",
        fontSize: "12px",
        minWidth: "134.4px",
        maxWidth: "134.4px",
      },

      [theme.breakpoints.down(MOBILE_MENU_THRESHOLD_WIDTH)]: {
        display: "none",
      },
    },
    itemBtn: {
      "& i": {
        color: "#9ab0bc",
        fontSize: "24px",
        height: "20px",
        width: "16px",
        [theme.breakpoints.down(540)]: {
          fontSize: "12px",
        },
      },
      [theme.breakpoints.down(MOBILE_MENU_THRESHOLD_WIDTH)]: {
        display: "none",
      },
    },
    studyLink: {
      position: "fixed",
      bottom: "20px",
      left: "20px",
      width: "auto",
      height: "28px",
      borderRadius: "4px",
      backgroundColor: "#fff",
      boxShadow: "0 2px 4px rgb(0 0 0 / 14%)",
      zIndex: 2000,
      perspective: "300px",
      transition: "0s linear",
      display: "flex",
      alignContent: "center",
      alignItems: "center",
      justifyContent: "center",
      padding: "0 12px",
      cursor: "pointer",
    },
    banner: {
      width: "97px",
      height: "19px",
    },
    sidebarRight: {
      transition: ".3s cubic-bezier(.4,.4,0,1)",
      zIndex: 20,
      position: "fixed",
      left: 300,
      top: 0,
      width: "300px",
      height: "100%",
      background: "#364750",
      boxShadow: "0px 6px 15px 0px rgb(0 0 0 / 20%)",
      overflow: "scroll",
      [theme.breakpoints.down(MOBILE_MENU_THRESHOLD_WIDTH)]: {
        width: "calc(100% - 48px)",
        left: "24px",
        top: "48px",
        height: "709px",
        maxHeight: "80%",
        zIndex: 20,
        borderRadius: "5px",
      },
    },
    rightHeader: {
      height: "96px",
      background: "#475b69",
      display: "flex",
      alignItems: "center",
      justifyContent: "right",
      padding: "0 16px",
      cursor: "pointer",
      [theme.breakpoints.down(MOBILE_MENU_THRESHOLD_WIDTH)]: {
        borderTopLeftRadius: "5px",
        borderTopRightRadius: "5px",
      },
    },
    closeIcon: {
      "& i": {
        fontSize: "30px",
        lineHeight: "30px",
        color: "#fff",
      },
    },
    rightContont: {
      padding: "0 12px",
    },
    rightItem: {
      height: "72px",
      display: "flex",
      alignItems: "center",
      padding: "0 12px",
      borderBottom: "1px solid #475b69",
      transition: ".3s cubic-bezier(.4,.4,0,1)",
      cursor: "pointer",
      "&:hover": {
        opacity: 0.5,
      },
    },
    rightArrow: {
      "& i": {
        color: "#9ab0bc",
        fontSize: "20px",
        margin: "0px 12px 0px 0px",
      },
    },
    rightItemContent: {
      color: "#ffffff",
      //fontFamily: "ヒラギノ角ゴ W6 JIS2004",
      fontFamily: "Noto Sans JP",
      fontSize: "16px",
      fontWeight: 600,
      height: "auto",
      letterSpacing: "0.15em",
      lineHeight: "1.4",
      padding: "0 12px",
      [theme.breakpoints.down(540)]: {
        fontSize: "16px",
      },
    },
    closePart: {
      display: "none",
      [theme.breakpoints.down(MOBILE_MENU_THRESHOLD_WIDTH)]: {
        width: "100%",
        position: "fixed",
        top: 0,
        height: "100%",
        display: "flex",
        zIndex: 2,
        backgroundColor: "rgb(0, 0, 0, 0.2)",
      },
    },
    closeRootIcon: {
      cursor: "pointer",
      "& i": {
        color: "#fff",
        fontSize: "35px",
        lineHeight: "35px",
      },
    },
    closeRightPart: {
      position: "fixed",
      top: 0,
      left: 600,
      width: "calc(100% - 600px)",
      height: "100%",
      display: "flex",
      justifyContent: "right",
      padding: "30px",
      zIndex: 2,
      backgroundColor: "rgb(0, 0, 0, 0.2)",
      boxSizing: "border-box",
      [theme.breakpoints.down(840)]: {
        display: "none",
      },
    },
    closeRightRootIcon: {
      cursor: "pointer",
      "& i": {
        color: "#000",
        fontSize: "35px",
        lineHeight: "35px",
      },
    },
    displayNone: {
      display: "none",
    },
    displayShow: {
      display: "unset",
    },
    cate1_id_1: {
      "&$itemImg": {
        backgroundImage: `url(${LOGO_MAP.searchPc})`,
      },
      [theme.breakpoints.down(MOBILE_MENU_THRESHOLD_WIDTH)]: {
        "&$itemImg": {
          backgroundImage: `url(${LOGO_MAP.searchGray})`,
        },
      },
    },
    cate1_id_2: {
      "&$itemImg": {
        backgroundImage: `url(${LOGO_MAP.serviceForCompanyPc})`,
      },
    },
    cate1_id_3: {
      "&$itemImg": {
        backgroundImage: `url(${LOGO_MAP.usagePc})`,
      },
    },
    cate1_id_4: {
      "&$itemImg": {
        backgroundImage: `url(${LOGO_MAP.accountPc})`,
      },
      [theme.breakpoints.down(MOBILE_MENU_THRESHOLD_WIDTH)]: {
        "&$itemImg": {
          backgroundImage: `url(${LOGO_MAP.accountGray})`,
        },
      },
    },
    cate1_id_5: {
      [theme.breakpoints.down(MOBILE_MENU_THRESHOLD_WIDTH)]: {
        "&$itemImg": {
          backgroundImage: `url(${LOGO_MAP.homeGray})`,
        },
        // "&$sidebarItemMobileCaption": {
        //   color: "#6AC6C6",
        // },
      },
    },
    cate1_id_6: {
      [theme.breakpoints.down(MOBILE_MENU_THRESHOLD_WIDTH)]: {
        "&$itemImg": {
          backgroundImage: `url(${LOGO_MAP.usageGray})`,
        },
      },
    },
    cate1_id_7: {
      [theme.breakpoints.down(MOBILE_MENU_THRESHOLD_WIDTH)]: {
        "&$itemImg": {
          backgroundImage: `url(${LOGO_MAP.contactGray})`,
        },
        "&$active": {
          opacity: 0.5,
        },
      },
    },
    cate1_id_1_active: {
      [theme.breakpoints.down(440)]: {
        "&$itemImg": {
          backgroundImage: `url(${LOGO_MAP.searchGreen})!important`,
        },
        "&$sidebarItemMobileCaption": {
          color: "#6AC6C6",
        },
      },
    },
    cate1_id_2_active: {
      opacity: 1,
    },
    cate1_id_3_active: {
      opacity: 11,
    },
    cate1_id_4_active: {
      [theme.breakpoints.down(440)]: {
        "&$itemImg": {
          backgroundImage: `url(${LOGO_MAP.accountGreen})!important`,
        },
        "&$sidebarItemMobileCaption": {
          color: "#6AC6C6",
        },
      },
    },
    cate1_id_5_active: {
      [theme.breakpoints.down(440)]: {
        "&$itemImg": {
          backgroundImage: `url(${LOGO_MAP.homeGreen})!important`,
        },
        "&$sidebarItemMobileCaption": {
          color: "#6AC6C6",
        },
      },
    },
    cate1_id_6_active: {
      [theme.breakpoints.down(440)]: {
        "&$itemImg": {
          backgroundImage: `url(${LOGO_MAP.usageGreen})!important`,
        },
        "&$sidebarItemMobileCaption": {
          color: "#6AC6C6",
        },
      },
    },
    cate1_id_7_active: {
      [theme.breakpoints.down(440)]: {
        "&$itemImg": {
          backgroundImage: `url(${LOGO_MAP.contactGreen})!important`,
        },
        "&$sidebarItemMobileCaption": {
          color: "#6AC6C6",
        },
      },
    },
  })
);
