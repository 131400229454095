import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        displayNone: {
            display: "none",
        },
        loaderWrapper: {
            position: "fixed",
            zIndex: 99998,
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.3)",
            overflowY: "scroll",
            padding: "16px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontFamily: "Noto Sans JP",
        },
        modalRoot: {
            padding: "30px 35px",
            boxSizing: "border-box",
            width: "620px",
            height: "fit-content",
            background: "#fff",
            boxShadow: "0px 0px 30px rgba(55, 55, 79, 0.05)",
            borderRadius: "10px",
            textAlign: "center",
            margin: "auto",
            [theme.breakpoints.down(769)]: {
                minWidth: "100% !important",
                width: "100% !important",
            },
        },
        title: {
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "20px",
            lineHeight: "50px",
            color: "#55727e",
        },
        mainContainer: {
            padding: "18px 50px 0 50px",
            [theme.breakpoints.down(540)]: {
                padding: 0,
            },
        },
        descContainer: {
            color: "#000",
            fontWeight: 400,
            fontSize: "18px",
            lineHeight: "29px",
            textAlign: "center",
            marginTop: "5px",
            marginBottom: "30px",
        },
        btnContainer: {
            marginTop: "36px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            [theme.breakpoints.down(570)]: {
                display: "block",
            },
        },
        confirmBtn: {
            margin: "0 auto 20px",
            width: "200px",
            maxWidth: "100%",
            [theme.breakpoints.down(570)]: {
                marginLeft: "0px",
            },
        },
        form: {
            display: "flex",
            alignItems: "center",
            marginBottom: "10px",
        },
        formTitle: {
            minWidth: "170px",
            marginRight: "5px",
            color: "#000",
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "29px",
            textAlign: "center",
        },
        message: {
            minWidth: "100px",
            //margin: "0 0 30px 0",
            margin: "15px 0 15px 0",
            fontWeight: 800,
            fontSize: "14px",
            lineHeight: "29px",
            textAlign: "left",
        },
        errorMessage: {
            color: "red",
        },
        resultMessage: {
            color: "green",
        },
        formInput: {
            "&:focus-within": {
                outline: "2px solid #6AC6C6",
            },
        },
        inLine: {
            display: "flex",
        },
        rememberMe: {
            display: "flex",
            alignItems: "center",
            color: "#333333",
            fontSize: "14px",
            lineHeight: 1.4,
        },
        discription: {
            display: "inline-block",
            color: "#333333",
            fontSize: "14px",
            lineHeight: 1.4,
            margin: "18px 0",
            cursor: "pointer",
          },
    })
);
