import { createStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import {MOBILE_MENU_THRESHOLD_WIDTH} from "../config/constant";
export const LayoutStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      //background: "#f8f7f6",
      /* background: "#000",
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100vh", */
    },
    container: {
      background: "#ebeff2",
      width: "calc(100% - 300px)",
      marginLeft: "300px",
      minHeight: "calc(100vh - 163px)",
      [theme.breakpoints.down(MOBILE_MENU_THRESHOLD_WIDTH)]: {
        width: "100%",
        marginLeft: 0,
        transitionDelay: "300ms",
        transitionDuration: "1000ms",
      },
    },
    displayShow: {
      display: "unset !important",
    },
  })
);
