import { REQUEST_API_URL } from "../config/constant";

export async function ApiUsersIdNoLoginCheck(id:any){
    const response: any = await fetch(REQUEST_API_URL + '/users/' + id, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-store',
        credentials: 'include',
        redirect: 'error',
        referrerPolicy: 'no-referrer-when-downgrade',
    })

    return response.json();
}