import { REQUEST_API_URL } from "../config/constant";

export async function ApiUsersInvitation(data: any) {
    const response: any = await fetch(REQUEST_API_URL + '/users/invitation', {
        method: 'POST',
        mode: 'cors',
        cache: 'no-store',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        },
        redirect: 'error',
        referrerPolicy: 'no-referrer-when-downgrade',
        body: JSON.stringify(data)
    })

    return response.json();
}