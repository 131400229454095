import { createStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import LogoImg from "../../assets/images/header-logo.png";
import LogoMobile from "../../assets/images/logo.png";
import {MOBILE_MENU_THRESHOLD_WIDTH} from "../../config/constant";
export const HeaderStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "right",
      alignItems: "center",
      background: "#fff",
      paddingRight: "24px",
      height: "96px",
      [theme.breakpoints.down(840)]: {
        justifyContent: "space-between",
        height: "64px",
      },
      [theme.breakpoints.down(MOBILE_MENU_THRESHOLD_WIDTH)]: {
        height: "80px",
      },
    },
    menu: {
      display: "none",
      color: "#475b69",
      height:"100%",
      [theme.breakpoints.down(840)]: {
        display: "flex",
        alignItems: "center",
        paddingLeft: "12px",
      },
      "& i": {
        cursor: "pointer",
        fontSize: "24px",
        lineHeight: "24px",
      },
      [theme.breakpoints.down(MOBILE_MENU_THRESHOLD_WIDTH)]: {
        paddingLeft: "24px",
      },
    },
    menuAnchor:{
      height:"100%",
    },
    menuImg: {
      cursor: "pointer",
      display: "block",
    },
    logoImg: {
      width: "42px",
      height: "42px",
      marginLeft: "12px",
      cursor: "pointer",
      backgroundImage:`url(${LogoImg})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      [theme.breakpoints.down(475)]: {
      },
      [theme.breakpoints.down(MOBILE_MENU_THRESHOLD_WIDTH)]: {
        margin:"auto",
        marginLeft: "0px",
        width: "42px",
      },
    },
    searchRoot: {
      marginLeft: "12px",
      width: "calc(100% - 348px)",
      height: "50px",
      padding: "0 24px",
      display: "flex",
      background: "#ebeff2",
      borderRadius: "25px",
      alignItems: "center",
      //minWidth: "200px",
      minWidth: "calc(100% - 348px)",
      //transitionDelay: "300ms",
      //transitionDuration: "1000ms",
      [theme.breakpoints.down(840)]: {
        width: "100%",
        height: "36px",
      },
      "&:focus-within":{
        outline: "2px solid #6AC6C6",
      },
      [theme.breakpoints.down(MOBILE_MENU_THRESHOLD_WIDTH)]: {
        height: "56px",
        width: "70%",
      },
    },
    searchIcon: {
      "& i": {
        fontSize: "21px",
        lineHeight: "28px",
        color: "#9ab0bc",
        [theme.breakpoints.down(840)]: {
          fontSize: "14px",
          lineHeight: "18px",
        },
      },
    },
    /* searchInput: {
      color: "#9ab0bc",
      //fontFamily: "ヒラギノ角ゴ W3 JIS2004",
      fontFamily: "Noto Sans JP",
      fontSize: "16px",
      fontWeight: 400,
      height: "auto",
      lineHeight: "1.4",
      margin: "0px 0px 0px 12px",
      textAlign: "center",
      textOverflow: "ellipsis",
      overflow: "hidden",
      [theme.breakpoints.down(840)]: {
        fontSize: "14px",
      },
    }, */
    inputArea: {
      height: "0px",
      "&:focus":{
        outline: "none",
      },
    },
  })
);
