import { ActionButton2Styles } from "./ActionButton2Styles";
import LoadingButton from '@mui/lab/LoadingButton';
import { ReactElement } from "react";

interface ActionButton2Props {
  endIcon?: ReactElement;
  className?: any;
  type?: string;
  loading?: boolean;
  content: string;
  action?: () => void;
  disable?: boolean;
}

export default function ActionButton2({
  endIcon,
  className,
  type,
  loading,
  content,
  action,
  disable,
}: ActionButton2Props) {
  const classes = ActionButton2Styles();

  return (
    <LoadingButton
      loading={loading}
      endIcon={endIcon}
      loadingPosition="end"
      disabled={disable}
      onClick={disable ? () => {} : action}
      className={className}
      style={type === "dark" ? {
        display: "flex",
        flexWrap: "nowrap",
        justifyContent: "center",
        alignItems: "center",
        background: "#000000",
        borderRadius: "10px",
        transitionDelay: "0ms",
        transitionDuration: "200ms",
        padding: "12px 48px",
        border: "none",
        margin: "4px 8px",

        color: "#fff",
        fontFamily: "Noto Sans JP",
        fontSize: "14px",
        fontWeight: 700,
        letterSpacing: "0.05em",
        lineHeight: "1.9",

        } : {
        display: "flex",
        flexWrap: "nowrap",
        justifyContent: "center",
        alignItems: "center",
        background: "#ebeff2",
        borderRadius: "10px",
        transitionDelay: "0ms",
        padding: "12px 48px",
        transitionDuration: "600ms",
        border: "none",
        margin: "4px 8px",

        color: "#000",
        fontFamily: "Noto Sans JP",
        fontSize: "14px",
        fontWeight: 700,
        letterSpacing: "0.05em",
        lineHeight: "1.9",
        }}
    >
      {content}
    </LoadingButton>
  );
}
