import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { createLoginMonitor } from "Utilities/LoginManager";

import { Account } from "pages/Account/Account";
import { AccountSetting } from "pages/Account/Setting/AccountSetting";
import { Login } from "pages/Login/Login";
import { Popular } from "pages/Popular/Popular";
import { Search } from "pages/Search/Search";
import { ResetPassword } from "pages/ResetPassword/ResetPassword";
import { DefinitiveRegistration } from "pages/DefinitiveRegistration/DefinitiveRegistration";
import { EmailChange } from "pages/EmailChange/EmailChange";
import { Usage } from "pages/Usage/Usage";
import { Contact } from "pages/Contact/Contact";
import { ErrorRoute } from "pages/ErrorRoute/ErrorRoute";
import { Category } from "pages/Category/Category";
import { CategorySlug } from "pages/Category/CategorySlug/CategorySlug";

import {
  PATH_RESET_PASSWORD,
  PATH_DEFINITIVE_REGISTRATION,
  CATEGORY_DIR_NAME,
  SERVICES_DIR_NAME,
  ACCOUNT_LINK,
  ACCOUNT_SETTING_LINK,
  USAGE_LINK,
  CONTACT_LINK,
  SERVICE_APPLICATIONS_COMPLETE_PAGE_LINK,
  CONTACT_COMPLETE_PAGE_LINK,
  POPULAR_DIR_NAME,
  SEARCH_DIR_NAME,
  BUSINESS_EMAIL_CHANGE_LINK,
  PRIVATE_EMAIL_CHANGE_LINK,
  MAP_LINK,
  PASSWORD_RESET_FORM_LINK,
  FAMILY_REGISTRATION,
  FAMILY_REGISTRATION_FINISH,
} from "config/constant";
import { Complete } from "pages/Complete/Complete";
import { Landing } from "pages/Landing/Landing";
import Map from "pages/Map/Map";
import SideBridge from "Utilities/SideBridge";
import { ScrollToTop } from "Utilities/scrollToTop";
import { PasswordChangeForm } from "pages/PasswordChangeForm/PasswordChangeForm";
import { FamilyRegistrationForm } from "pages/FamilyRegistrationForm/FamilyRegistrationForm";
import { FamilyRegistrationFinish } from "pages/FamilyRegistrationFinish/FamilyRegistrationFinish";

const AppRoutes = () => {
  const loginMonitor = createLoginMonitor();

  return (
    <>
      <Router>
        <ScrollToTop />
        <Routes>
          <Route
            path={`${PATH_DEFINITIVE_REGISTRATION}`}
            element={<DefinitiveRegistration />}
          />
          <Route path={`${PATH_RESET_PASSWORD}`} element={<ResetPassword />} />

          <Route
            path={PASSWORD_RESET_FORM_LINK}
            element={<PasswordChangeForm />}
          />
          <Route
            path={FAMILY_REGISTRATION}
            element={<FamilyRegistrationForm />}
          />
          <Route
            path={FAMILY_REGISTRATION_FINISH}
            element={<FamilyRegistrationFinish />}
          />
          <Route path="*" element={<ErrorRoute />} />
        </Routes>
      </Router>
    </>
  );
};

export default AppRoutes;
