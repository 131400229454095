import { REQUEST_API_URL, RES_STATUS_NOT_LOGIN } from "../config/constant";

export async function ApiSidebarNoLoginCheck(){
    const response: any = await fetch(REQUEST_API_URL + '/sidebar', {
        method: 'GET',
        mode: 'cors',
        cache: 'no-store',
        credentials: 'include',
        redirect: 'error',
        referrerPolicy: 'no-referrer-when-downgrade',
    })

    return response.json();
}