import clsx from "clsx";
import { ActionInputStyles } from "./ActionInputStyles";

export interface ActionInputProps {
  className?: any;
  placeholder?: string;
  value: string;
  action: (e: any) => void;
  onKeyDown?: (e: any) => void;
  disable?: boolean;
  type?: string;
  autoComplete?: string;
}

export default function ActionInput({
  className,
  placeholder,
  value,
  action,
  onKeyDown,
  disable,
  type,
  autoComplete,
}: ActionInputProps) {
  const classes = ActionInputStyles();

  return (
    <input
      type={type}
      className={clsx(classes.root, className)}
      value={value}
      placeholder={placeholder}
      onChange={(e) => action(e.target.value)}
      onKeyDown={onKeyDown}
      autoComplete={autoComplete}
    />
  );
}
