import { REQUEST_API_URL } from "../config/constant";
import { loginMonitor } from "Utilities/LoginManager"

export async function auth(email: any, password: any, rememberMe: boolean, loginMonitor:loginMonitor) {

    const response: any = await fetch(REQUEST_API_URL + '/auth', {
        method: 'POST',
        mode: 'cors',
        cache: 'no-store',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
        redirect: 'error',
        body: JSON.stringify({email:email, password:password, remember_me:rememberMe})
    })
    
    return response.json();
}