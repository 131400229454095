import {
  COMPANY_ABOUT_URL,
  COMPANY_TERM_URL,
  COPYRIGHT,
  PRIVACY_POLICY_URL,
  SCTA_URL,
} from "config/constant";
import { useLocation } from "react-router-dom";

import { FooterStyles } from "./FooterStyles";
import LaunchIcon from "@mui/icons-material/Launch";
import clsx from "clsx";

export default function Footer() {
  const location = useLocation();
  const classes = FooterStyles();

  const urlPattern = /^\/services\/[a-fA-F0-9]{24}$/;
  const isDetail = urlPattern.test(location.pathname);

  /* const handleCompanyAbout = () => {
    window.open(
      SHISAKU_PAD_COMPANY_ABOUT_URL,
      "_blank"
    );
  };
  const handleCompanyPolicy = () => {
    window.open(
      SHISAKU_PAD_PRIVACY_POLICY_URL,
      "_blank"
    );
  };
  const handleCompanyTerm = () => {
    window.open(
      SHISAKU_PAD_COMPANY_TERM_URL,
      "_blank"
    );
  }; */

  return (
    <>
      <div className={clsx(classes.root, isDetail && classes.blakSpace)}>
        <a
          className={classes.link}
          href={COMPANY_ABOUT_URL}
          target="_blank"
          rel="noreferrer"
          //onClick={handleCompanyAbout}
        >
          <div className={classes.linkString}>運営会社</div>
          {/* <i className='far fa-external-link-alt'></i> */}
          <LaunchIcon style={{ fontSize: "20px" }} />
        </a>
        <a
          className={classes.link}
          href={PRIVACY_POLICY_URL}
          target="_blank"
          rel="noreferrer"
          //onClick={handleCompanyPolicy}
        >
          <div className={classes.linkString}>プライバシーポリシー</div>
          {/* <i className='far fa-external-link-alt'></i> */}
          <LaunchIcon style={{ fontSize: "20px" }} />
        </a>
        <a
          className={classes.link}
          href={COMPANY_TERM_URL}
          target="_blank"
          rel="noreferrer"
          //onClick={handleCompanyTerm}
        >
          <div className={classes.linkString}>ご利用規約</div>
          {/* <i className='far fa-external-link-alt'></i> */}
          <LaunchIcon style={{ fontSize: "20px" }} />
        </a>
        <a
          className={classes.link}
          href={SCTA_URL}
          target="_blank"
          rel="noreferrer"
          //onClick={handleCompanyTerm}
        >
          <div className={classes.linkString}>特定商取引法に基づく表記</div>
          {/* <i className='far fa-external-link-alt'></i> */}
          <LaunchIcon style={{ fontSize: "20px" }} />
        </a>
        <div className={classes.imLink}>
          <p>{COPYRIGHT}</p>
        </div>
      </div>
    </>
  );
}
