import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { Landing } from "pages/Landing/Landing";
import { Account } from "pages/Account/Account";
import { LayoutStyles } from "./LayoutStyle";
import Header from "./Header/Header";
import Sidebar from "./Sidebar/Sidebar";
import Footer from "./Footer/Footer";
import { Usage } from "pages/Usage/Usage";
import { Contact } from "pages/Contact/Contact";
import { useCallback, useEffect, useState } from "react";
import { AccountSetting } from "pages/Account/Setting/AccountSetting";
import { ErrorRoute } from "pages/ErrorRoute/ErrorRoute";
import { Category } from "pages/Category/Category";
import { CategorySlug } from "pages/Category/CategorySlug/CategorySlug";
import { Login } from "pages/Login/Login";
import { createLoginMonitor } from "../Utilities/LoginManager";
import { Complete } from "pages/Complete/Complete";
import Reveal from "react-awesome-reveal";
import { appear2 } from "config/Animations";

import {
  PATH_RESET_PASSWORD,
  PATH_DEFINITIVE_REGISTRATION,
  CATEGORY_DIR_NAME,
  SERVICES_DIR_NAME,
  ACCOUNT_LINK,
  ACCOUNT_SETTING_LINK,
  USAGE_LINK,
  CONTACT_LINK,
  SERVICE_APPLICATIONS_COMPLETE_PAGE_LINK,
  CONTACT_COMPLETE_PAGE_LINK,
  POPULAR_DIR_NAME,
  SEARCH_DIR_NAME,
  BUSINESS_EMAIL_CHANGE_LINK,
  PRIVATE_EMAIL_CHANGE_LINK,
} from "config/constant";
import { Popular } from "pages/Popular/Popular";
import { Search } from "pages/Search/Search";
import { ResetPassword } from "../pages/ResetPassword/ResetPassword";
import { DefinitiveRegistration } from "../pages/DefinitiveRegistration/DefinitiveRegistration";
import { EmailChange } from "pages/EmailChange/EmailChange";
import SideBridge from "../Utilities/SideBridge";

export default function Layout({ children }: { children?: React.ReactNode }) {
  const loginMonitor = createLoginMonitor();

  const classes = LayoutStyles();
  const [status, setStatus] = useState(true);
  const [sideBridge, setSideBridge] = useState(SideBridge());

  const [beforeunloadFlg, setBeforeunloadFlg] = useState(false);

  const onclick = () => {
    setStatus(true);
  };

  const onSidebarClose = () => {
    if (window.innerWidth > 839) {
      setStatus(true);
    } else {
      setStatus(false);
    }
  };

  useEffect(() => {
    if (window.innerWidth > 839) {
      setStatus(true);
    } else {
      setStatus(false);
    }
  }, []);

  const handleWindowResize = useCallback((event: any) => {
    if (window.innerWidth > 839) {
      setStatus(true);
    } else {
      setStatus(false);
    }
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [handleWindowResize]);

  const handleBeforeUnloadEvent = async () => {
    setBeforeunloadFlg(true);
  };

  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnloadEvent);
    return () =>
      window.removeEventListener("beforeunload", handleBeforeUnloadEvent);
  }, []);

  return (
    <>
      {beforeunloadFlg ? (
        <div className={classes.root}></div>
      ) : (
        <>
          <Sidebar
            handleClose={onSidebarClose}
            mobileStatus={status}
            loginStatusForSidebar={loginMonitor.isLogin()}
            sideBridge={sideBridge}
          />
          <div className={classes.container}>
            <Header mobileAction={onclick} />
            {children}
          </div>
          <Footer />
        </>
      )}
    </>
  );
}
