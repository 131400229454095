import { useStyles } from "./PromotionModalStyle";
import { useState, useEffect, useRef } from "react";

import kvImage from "../../assets/images/promotion_kv.png";
import googleQrImage from "../../assets/images/qr-google.png";
import googleButtonImage from "../../assets/images/btn-google.png";
import iosQrImage from "../../assets/images/qr-ios.png";
import iosButtonImage from "../../assets/images/btn-ios.png";

import React from "react";
import { ANDROID_NAVITVE_LINL, IOS_NAVITVE_LINL } from "config/constant";

interface PromotionModalProps {
  show: boolean;
  onClose: () => void;
}
export default function PromotionModal({ show, onClose }: PromotionModalProps) {
  const classes = useStyles();

  const [showStatus, setShowStatus] = useState(show);

  const rootRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (show) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    document.addEventListener("click", handleClick);
    return () => document.removeEventListener("click", handleClick);
    function handleClick(e: any) {
      if (rootRef && rootRef.current && contentRef && contentRef.current) {
        const root: any = rootRef.current;
        const content: any = contentRef.current;
        if (root.contains(e.target) && !content.contains(e.target)) {
          onClose();
        }
      }
    }
  }, [rootRef, contentRef, show]);

  useEffect(() => {
    setShowStatus(show);
  }, [show]);

  return (
    <>
      <div
        className={showStatus ? classes.loaderWrapper : classes.displayNone}
        ref={rootRef}
      >
        <div className={classes.modalRoot} ref={contentRef}>
          <div className={classes.cancelButton} onClick={onClose}>
            ×
          </div>
          <div className={classes.mainContainer}>
            <div className={classes.kvContainer}>
              <img src={kvImage} className={classes.kvImage} alt="key-visual" />
            </div>
            <div className={classes.descContainer}>
              アプリの方が高機能！インストールはこちらから
            </div>
            <div className={classes.btnsContainer}>
              <div className={classes.nativeLink}>
                <a
                  href={IOS_NAVITVE_LINL}
                  className={classes.linkBtn}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={iosButtonImage}
                    className={classes.nativeButtonImage}
                    alt="ios-app-link"
                  />
                </a>
                <img
                  src={iosQrImage}
                  className={classes.nativeQrImage}
                  alt="ios-app-qr"
                />
              </div>
              <div className={classes.nativeLink}>
                <a
                  href={ANDROID_NAVITVE_LINL}
                  className={classes.linkBtn}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={googleButtonImage}
                    className={classes.nativeButtonImage}
                    alt="google-app-link"
                  />
                </a>
                <img
                  src={googleQrImage}
                  className={classes.nativeQrImage}
                  alt="google-app-qr"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
