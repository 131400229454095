import { SidebarStyles } from "./SidebarStyles";
import LogoImg from "../../assets/images/logo-hz.png";
import default_avatar from "../../assets/images/avatar.png";
import {
  ACCOUNT_LINK,
  REQUEST_AVATAR_IMG_URL,
  SidebarData,
} from "config/constant";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import clsx from "clsx";

import { ApiSidebarNoLoginCheck } from "Apis/ApiSidebarNoLoginCheck";
import { ApiLogout } from "Apis/ApiLogout";
import { ApiClientsId } from "Apis/ApiClientsId";
import { ApiUsersIdNoLoginCheck } from "Apis/ApiUsersIdNoLoginCheck";

import { createLoginMonitor } from "Utilities/LoginManager";
import { ApiAvatarImagesId } from "Apis/ApiAvatarImagesId";
import { AfterApiIsLogin } from "Utilities/AuthUtility";
import { ISideBridge } from "../../Utilities/SideBridge";
import { fullNameOrFamilyName } from "Utilities/appHelper";
import { Link } from "react-router-dom";

interface SidebarProps {
  className?: any;
  mobileStatus: boolean;
  handleClose: () => void;
  loginStatusForSidebar: boolean;
  sideBridge: ISideBridge;
}

export default function Sidebar({
  className,
  handleClose,
  mobileStatus,
  loginStatusForSidebar,
  sideBridge,
}: SidebarProps) {
  const classes = SidebarStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const [rightPanel, setRightPanel] = useState(false);
  const [rightRouters, setRightRouters] = useState<any>();
  const [showStatus, setShowStatus] = useState<boolean>(mobileStatus);
  const [isHidden, setIsHidden] = useState(false);
  const loginMonitor = createLoginMonitor();

  type categoryClassType =
    | "cate1_id_1"
    | "cate1_id_2"
    | "cate1_id_3"
    | "cate1_id_4"
    | "cate1_id_5"
    | "cate1_id_6"
    | "cate1_id_7";

  type categoryActiveClassType =
    | "cate1_id_1_active"
    | "cate1_id_2_active"
    | "cate1_id_3_active"
    | "cate1_id_4_active"
    | "cate1_id_5_active"
    | "cate1_id_6_active"
    | "cate1_id_7_active";

  const categoryClassMap: { [key: number]: categoryClassType } = {
    1: "cate1_id_1",
    2: "cate1_id_2",
    3: "cate1_id_3",
    4: "cate1_id_4",
    5: "cate1_id_5",
    6: "cate1_id_6",
    7: "cate1_id_7",
  };

  const categoryActive: { [key: number]: categoryActiveClassType } = {
    1: "cate1_id_1_active",
    2: "cate1_id_2_active",
    3: "cate1_id_3_active",
    4: "cate1_id_4_active",
    5: "cate1_id_5_active",
    6: "cate1_id_6_active",
    7: "cate1_id_7_active",
  };

  const initialUserValues = {
    id: "",
    client_id: "",
    last_name: "",
    first_name: "",
    full_name: "",
    phone: "",
    business_email: "",
    private_email: "",
    image_id: "",
  };

  const [userValues, setUserValues] = useState(initialUserValues);
  const [avatarURL, setAvatarURL] = useState();
  const [clientName, setClientName] = useState();

  const [dataAdd, setDataAdd] = useState<boolean>(false);
  const [sidebarCategoryListLength, setSidebarCategoryListLength] =
    useState<Number>(SidebarData.length);
  const [sidebarCategoryList, setSidebarCategoryList] = useState<any[]>([]);

  useEffect(() => {
    init();
    if (window.innerWidth > 839) {
      setIsHidden(false);
    } else {
      setIsHidden(true);
    }
  }, []);

  useEffect(() => {
    init();
  }, [loginStatusForSidebar]);

  async function init() {
    if (loginStatusForSidebar) {
      var response = await ApiSidebarNoLoginCheck();
      if (response["result-object"].length > 0) {
        response["result-object"].forEach((item: any) => {
          cat1_enable = false;
          if (item.enable) {
            var cat1_enable = true;
          } else {
            item.content = item.content + "（非公開）";
          }
          item.router.routers.forEach((item2: any) => {
            if (cat1_enable && item2.enable) {
            } else {
              item2.name = item2.name + "（非公開）";
            }
          });
        });
      }
      setSidebarData(response["result-object"]);
      var response2 = await ApiUsersIdNoLoginCheck(
        localStorage.getItem("user_key")
      );
      if (
        response2["result-object"] !== null &&
        response2["result-object"].id !== void 0
      ) {
        setUserData(response2["result-object"]);
      } else {
        //if(loginMonitor.isLogin()) {
        if (localStorage.getItem("user_key") !== "") {
          handleLogout();
        }
      }
    }
  }

  async function setUserData(data: any) {
    setUserValues({
      ...userValues,
      id: data.id,
      client_id: data.client_id,
      last_name: data.last_name,
      first_name: data.first_name,
      full_name: data.full_name,
      phone: data.phone,
      business_email: data.business_email,
      private_email: data.private_email,
      image_id: data.image_id,
    });

    var url: any = default_avatar;
    if (data.image_id !== void 0 && data.image_id !== null) {
      var response = await ApiAvatarImagesId(data.image_id);
      loginCheck(response);
      url =
        REQUEST_AVATAR_IMG_URL + "/" + response["result-object"].image_filename;
    }
    setAvatarURL(url);

    var response2 = await ApiClientsId(data.client_id);
    loginCheck(response2);

    if (response2["result-object"] !== null) {
      setClientName(response2["result-object"].name);
    } else {
      handleLogout();
    }
  }

  function setSidebarData(data: any) {
    sidebarCategoryList.length = 0;
    SidebarData.concat(data).forEach((item: any) =>
      sidebarCategoryList.push(item)
    );
    sidebarCategoryList.sort(
      (prevObject, followObject) => prevObject.sort_key - followObject.sort_key
    );
  }

  function loginCheck(data: any) {
    AfterApiIsLogin(data["result-status"]);
  }

  useEffect(() => {
    setShowStatus(mobileStatus);
  }, [mobileStatus]);

  /* const handleLogo = () => {
    navigate("/");
  }; */

  /* const handleAccount = () => {
    navigate(ACCOUNT_LINK);
  }; */

  const handleLogout = async () => {
    await ApiLogout();
    loginMonitor.logout();
    window.location.replace("/");
  };

  const handleRightClose = () => {
    setRightPanel(false);
    isHidden && setShowStatus(false);
  };

  const handleWindowResize = useCallback((event: any) => {
    if (window.innerWidth > 839) {
      setIsHidden(false);
    } else {
      setIsHidden(true);
    }
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [handleWindowResize]);

  const handleRighPanel = (e: any) => {
    console.log("ここです", e);
    if (!e.show) {
      setRightPanel(false);
      //navigate(e.link);
    } else {
      setRightPanel(true);
      setRightRouters(e.routers);
    }
  };

  const handleLink = (link: string) => {
    setRightPanel(false);
    isHidden && setShowStatus(false);
    setRightPanel(false);
    //navigate(e);
    //link.indexOf("http") === 0 ? window.open(link, '_blank') : navigate(link);
    handleClose();
    //window.location.reload();
  };

  const isActive = (path: string): Boolean => {
    return location.pathname === path && !rightPanel;
  };

  return (
    <>
      <div className={clsx(classes.root, className, classes.displayShow)}>
        <Link
          to={"/"}
          className={classes.logoA}
          onClick={() => {
            handleRightClose();
            //handleLogo()
          }}
        >
          <img src={LogoImg} className={classes.logoImg} alt="logo" />
        </Link>
        <Link
          to={ACCOUNT_LINK}
          className={classes.avatarRoot}
          onClick={() => {
            handleRightClose();
            //handleAccount()
          }}
        >
          {/* <img src={avatar} className={classes.avatarImg} /> */}
          {
            /* loginMonitor.isLogin() ? */
            avatarURL ? (
              <>
                <img src={avatarURL} className={classes.avatarImg} />
              </>
            ) : (
              <></>
            )
          }
          <div className={classes.avatarName}>
            {fullNameOrFamilyName(
              userValues.full_name,
              userValues.first_name,
              userValues.last_name
            )}
          </div>
          <div className={classes.avatarJob}>{clientName}</div>
        </Link>
        <div className={classes.logoutBtn} onClick={handleLogout}>
          {loginMonitor.isLogin() ? "ログアウト" : ""}
        </div>
        <div className={classes.sidebarContent}>
          {sidebarCategoryList.map((item: any, key: any) => {
            if (item.router.show) {
              return (
                <div
                  className={clsx(
                    classes.sidebarItem,
                    !item.use_pc && classes.disable_pc,
                    !item.use_mobile && classes.disable_mobile
                  )}
                  key={key}
                  onClick={sideBridge.bridgeOnClick(item.id, () => {
                    setRightRouters([]);
                    handleRighPanel(item.router);
                  })}
                >
                  <div
                    //src={item.img}
                    className={clsx(
                      classes.itemImg,
                      classes[categoryClassMap[item.id]],
                      rightPanel && classes[categoryActive[item.id]]
                    )}
                    //alt='sidebar-icon'
                  ></div>
                  <div className={classes.itemContent}>{item.content}</div>
                  <div className={classes.itemBtn}>
                    <i className="fas fa-caret-right"></i>
                  </div>
                  <div
                    className={clsx(
                      classes.sidebarItemMobileCaption,
                      classes[categoryClassMap[item.id]],
                      rightPanel && classes[categoryActive[item.id]]
                    )}
                  >
                    {item.mobile_caption}
                  </div>
                </div>
              );
            } else {
              return (
                <Link
                  to={item.router.link}
                  className={clsx(
                    classes.sidebarItem,
                    !item.use_pc && classes.disable_pc,
                    !item.use_mobile && classes.disable_mobile
                  )}
                  key={key}
                  onClick={sideBridge.bridgeOnClick(item.id, () => {
                    setRightRouters([]);
                    handleRighPanel(item.router);
                  })}
                >
                  <div
                    //src={item.img}
                    className={clsx(
                      classes.itemImg,
                      classes[categoryClassMap[item.id]],
                      isActive(item.router.link) &&
                        classes[categoryActive[item.id]]
                    )}
                    //alt='sidebar-icon'
                  ></div>
                  <div className={classes.itemContent}>{item.content}</div>
                  <div className={classes.itemBtn}>
                    <i className="fas fa-caret-right"></i>
                  </div>
                  <div
                    className={clsx(
                      classes.sidebarItemMobileCaption,
                      classes[categoryClassMap[item.id]],
                      isActive(item.router.link) &&
                        classes[categoryActive[item.id]]
                    )}
                  >
                    {item.mobile_caption}
                  </div>
                </Link>
              );
            }
          })}
        </div>
      </div>
      <div
        className={rightPanel ? classes.closePart : classes.displayNone}
        onClick={handleClose}
      ></div>
      {rightPanel ? (
        <>
          <div className={classes.sidebarRight}>
            <div className={classes.rightHeader}>
              <div
                className={classes.closeIcon}
                onClick={() => setRightPanel(false)}
              >
                <i className="fal fa-times"></i>
              </div>
            </div>
            <div className={classes.rightContont}>
              {rightRouters?.map((item: any, key: any) => {
                return (
                  <Link
                    to={item.link}
                    target={item.link.indexOf("http") === 0 ? "_blank" : ""}
                    className={classes.rightItem}
                    key={key}
                    /* onClick={() => handleLink(item?.link)}> */
                    onClick={() => handleLink(item?.link)}
                    rel="noreferrer"
                  >
                    <div className={classes.rightArrow}>
                      <i className="far fa-arrow-right"></i>
                    </div>
                    <div className={classes.rightItemContent}>{item?.name}</div>
                  </Link>
                );
              })}
            </div>
          </div>
          <div className={classes.closeRightPart} onClick={handleRightClose}>
            {/* <div className={classes.closeRightRootIcon}>
              <i className='fal fa-times'></i>
            </div> */}
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
}
