import ActionButton from "components/ActionButton/ActionButton";
import ActionInput from "components/ActionInput/ActionInput";
import { useEffect, useState } from "react";
import { useStyles } from "./DefinitiveRegistrationStyle";
import { createLoginMonitor } from "../../Utilities/LoginManager";
import { RES_STATUS_OK } from "config/constant";
import { useParams } from "react-router-dom";
import { ApiDefinitiveRegistration } from "../../Apis/ApiDefinitiveRegistration";
import MessageModal from "components/MessageModal/MessageModal";
import { auth } from "Auth/Auth";
import clsx from "clsx";
import PromotionModal from "components/PromotionModal/PromotionModal";
import Layout from "layout/Layout";

export const DefinitiveRegistration = () => {
  const classes = useStyles();
  const [password, setPassword] = useState("");
  const [conPassword, setConPassword] = useState("");
  const [email, setEmail] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const { token } = useParams();

  const loginMonitor = createLoginMonitor();

  const handleNext = async () => {
    setStartDefinitiveRegistrationStatus(true);

    if (password.length < 8) {
      setErrMsg("パスワードは8文字以上入力してください。");
      return;
    } else {
      if (!(password.match(/([a-zA-Z])/) && password.match(/([0-9])/))) {
        setErrMsg("パスワードは英数字混合にしてください。");
        return;
      } else {
        if (password !== conPassword) {
          setErrMsg("パスワードとパスワード（再入力）が一致しません。");
          return;
        }
      }
    }

    const response = await ApiDefinitiveRegistration(token, password);
    if (response["result-status"] === RES_STATUS_OK) {
      setEmail(response["result-object"].temporary_business_email);
      setDefinitiveRegistrationFinishStatus(true);
    } else {
      setErrMsg(response["message"]);
      return;
    }
  };

  const [
    startDefinitiveRegistrationStatus,
    setStartDefinitiveRegistrationStatus,
  ] = useState(false);
  const [
    definitiveRegistrationFinishStatus,
    setDefinitiveRegistrationFinishStatus,
  ] = useState(false);

  const [showPromotionModal, setShowPromotionModal] = useState(false);

  function handleDefinitiveRegistrationFinish() {
    setDefinitiveRegistrationFinishStatus(false);
    setShowPromotionModal(true);
  }

  const handlePromotionModal = () => {
    setShowPromotionModal(false);
    window.location.replace("/");
  };

  async function autoLogin() {
    const response = await auth(email, password, false, loginMonitor);
    if (response["result-status"] === RES_STATUS_OK) {
      localStorage.setItem("user_key", response["result-object"]["id"]);
      loginMonitor.login();
    } else {
      setErrMsg(response["message"]);
      return;
    }
  }

  useEffect(() => {
    if (
      startDefinitiveRegistrationStatus === true &&
      definitiveRegistrationFinishStatus === false
    ) {
      autoLogin();
    }
  }, [definitiveRegistrationFinishStatus]);

  return (
    <Layout>
      <div className={classes.loaderWrapper}>
        <div className={classes.modalRoot}>
          <div className={classes.title}>パスワードを設定</div>
          <div className={classes.mainContainer}>
            <div className={clsx(classes.message, classes.errorMessage)}>
              {errMsg
                ? errMsg.split("\n").map((item: any, key: any) => {
                    return (
                      <span key={key}>
                        {item}
                        <br />
                      </span>
                    );
                  })
                : ""}
            </div>
            <div className={classes.form}>
              <div className={classes.formTitle}>パスワード</div>
              <ActionInput
                value={password}
                type="password"
                action={(e) => setPassword(e)}
                placeholder="********"
                className={classes.formInput}
              />
            </div>
            <div className={classes.form}>
              <div className={classes.formTitle}>パスワード（再入力）</div>
              <ActionInput
                value={conPassword}
                type="password"
                action={(e) => setConPassword(e)}
                placeholder="********"
                className={classes.formInput}
              />
            </div>
          </div>
          <div className={classes.btnContainer}>
            <ActionButton
              content="登録"
              type="dark"
              className={classes.confirmBtn}
              action={handleNext}
            />
          </div>
        </div>
        <MessageModal
          title="アカウント登録完了"
          description={`アカウント登録が完了しました`}
          show={definitiveRegistrationFinishStatus}
          onClose={handleDefinitiveRegistrationFinish}
          action={handleDefinitiveRegistrationFinish}
        />
        {/* ここに促進モーダルを表示する */}
        <PromotionModal
          show={showPromotionModal}
          onClose={handlePromotionModal}
        />
      </div>
    </Layout>
  );
};
