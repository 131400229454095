import { Theme, makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  displayNone: {
    display: "none",
  },
  loaderWrapper: {
    position: "fixed",
    zIndex: 99998,
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    overflowY: "scroll",
    padding: "16px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  modalRoot: {
    position: "relative",
    padding: "0 0 35px 0",
    boxSizing: "border-box",
    width: "620px",
    height: "fit-content",
    background: "#fff",
    boxShadow: "0px 0px 30px rgba(55, 55, 79, 0.05)",
    borderRadius: "10px",
    textAlign: "center",
    margin: "auto",
    [theme.breakpoints.down(769)]: {
      minWidth: "100% !important",
      width: "100% !important",
      padding: "0 0 20px 0",
    },
  },
  headerContainer: {
    padding: "8px 0",
    fontWeight: "bold",
    borderBottom: "1px solid #eeeeee",
    fontSize: "16px",
    [theme.breakpoints.down(440)]: {
      padding: "12px 0",
    },
  },
  cancelButton: {
    fontWeight: 700,
    fontSize: "18px",
    position: "absolute",
    right: "20px",
    top: "8px",
    [theme.breakpoints.down(440)]: {
      top: "12px",
      color: "white",
    },
  },
  mainContainer: {
    padding: "50px 50px 10px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.down(440)]: {
      padding: "0",
    },
  },
  kvContainer: {
    paddingBottom: "32px",
  },
  kvImage: {
    width: "100%",
    borderRadius: "12px",
    [theme.breakpoints.down(440)]: {
      borderRadius: "10px 10px 0 0",
    },
  },
  descContainer: {
    color: "#000",
    fontFamily: "Noto Sans JP", //20230111
    fontWeight: 700,
    fontSize: "20px",

    textAlign: "center",
    marginTop: "5px",
    marginBottom: "30px",
    [theme.breakpoints.down(440)]: {
      fontSize: "16px",
      padding: "0 24px",
    },
  },
  actionContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: "20px",
  },
  btnsContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: "8px",
    fontWeight: "bold",
    padding: "12px 24px",
    [theme.breakpoints.down(570)]: {},
  },
  nativeLink: {
    width: "40%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down(440)]: {
      width: "45%",
    },
  },
  nativeButtonImage: {
    width: "240px",
    paddingBottom: "20px",
    [theme.breakpoints.down(440)]: {
      width: "100%",
      paddingBottom: "0",
    },
  },
  nativeQrImage: {
    width: "120px",
    [theme.breakpoints.down(440)]: {
      display: "none",
    },
  },
  linkBtn: {
    color: "white",
  },
  closeBtn: {
    marginBottom: "20px",
    width: "100% !important",
    [theme.breakpoints.down(570)]: {
      marginLeft: "0px",
    },
  },
}));
