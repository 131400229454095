import "./App.css";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import AppRoutes from "AppRoutes";
import Layout from "layout/Layout";
import { LoginProxy } from "Utilities/LoginManager";

function App() {
  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 540,
        md: 1025,
        lg: 1367,
        xl: 1600,
      },
    },
  });
  return (
    <ThemeProvider theme={theme}>
      <style>
        @import
        url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap');
      </style>
      <LoginProxy>
        <AppRoutes />
      </LoginProxy>
    </ThemeProvider>
  );
}

export default App;
