import React from "react";
import {SIDEBAR_ID_FUNC_LIST} from "../config/constant";
export interface ISideBridge{
    bridgeOnClick:(id:number, event:(...args:any)=>any)=>any,
    searchClick:(...args:any)=>any,
}

export default function SideBridge():ISideBridge{
    const sidebarOnclickFunMap:{[key:string]:(...args:any)=>any} = {}
    function bridgeOnClick< T extends (...args:any)=>any>(id:number,event:T):T{
        sidebarOnclickFunMap[SIDEBAR_ID_FUNC_LIST[id]] = event;
        return event;
    }

    function searchClick(...args:any):any{
        if(sidebarOnclickFunMap["search"]){
            return sidebarOnclickFunMap["search"](args);
        }else{
            return;
        }
    }

    return {
        bridgeOnClick:bridgeOnClick,
        searchClick:searchClick,
    }
}