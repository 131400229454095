import React, { useContext, useState } from "react";
import { REQUEST_API_URL, RES_STATUS_OK } from "../config/constant";
import { recordAccessLog } from "./appHelper";

type loginManagerProp = {
  isLogin: boolean;
  setIsLogin: React.Dispatch<React.SetStateAction<boolean>>;
};

export type loginMonitor = {
  logout: () => void;
  login: () => void;
  isLogin: () => boolean;
  /* checkLogin:(responseStatus:number)=>void */
};

function createContext<T>() {
  const context = React.createContext<T | undefined>(undefined);
  const useCtx = () => {
    const c = useContext(context);
    if (!c) throw new Error("useCtx must be inside a Provider with a value");
    return c;
  };
  return [useCtx, context.Provider] as const;
}

const [loginSts, SetLoginProvider] = createContext<loginManagerProp>();

export const createLoginMonitor = (): loginMonitor => {
  const context = loginSts();
  const login = () => {
    context.setIsLogin(true);
  };

  const logout = () => {
    localStorage.setItem("user_key", "");
    context.setIsLogin(false);
  };

  const isLogin = () => {
    return context.isLogin;
  };

  /*const checkLogin = (responseStatus:number) =>{
        context.setIsLogin(responseStatus !== RES_STATUS_NOT_LOGIN);
    }*/

  return {
    logout: logout,
    isLogin: isLogin,
    login: login,
    /* checkLogin:checkLogin */
  };
};

export const LoginProxy = ({ children }: { children?: React.ReactNode }) => {
  const [isLogin, setIsLogin] = useState<boolean>(false); //初めからログインしていることにする？
  const loginObj: loginManagerProp = {
    isLogin: isLogin,
    setIsLogin: setIsLogin,
  };

  if (!isLogin) {
    fetch(REQUEST_API_URL + "/isLogin", {
      method: "GET",
      mode: "cors",
      cache: "no-store",
      credentials: "include",
      redirect: "error",
      referrerPolicy: "no-referrer-when-downgrade",
    })
      .then((res) => res.json())
      .then((data) => {
        if (data["result-status"] === RES_STATUS_OK) {
          const userId = data["result-object"]["id"];
          const clientId = data["result-object"]["clientId"];
          localStorage.setItem("user_key", userId);
          // 非同期魔のまま
          recordAccessLog(userId, clientId);
        }
        loginObj.setIsLogin(data["result-status"] === RES_STATUS_OK);
      });
  }

  return <SetLoginProvider value={loginObj}>{children}</SetLoginProvider>;
};
