import clsx from "clsx";
import { ActionButtonStyles } from "./ActionButtonStyles";

interface ActionButtonProps {
  className?: any;
  type?: string;
  content: string;
  action?: () => void;
  disable?: boolean;
  href?: string;
}

export default function ActionButton({
  className,
  type,
  content,
  action,
  disable,
  href,
}: ActionButtonProps) {
  const classes = ActionButtonStyles();

  if (href) {
    return (
      <a href={href}
        className={clsx(
          className,
          type === "dark" ? classes.darkRoot : classes.lightRoot,
          disable ? classes.disable : classes.noDisable
        )}
        onClick={disable ? () => { } : action}>
        <p className={type === "dark" ? classes.lightDes : classes.darkDes}>
          {content}
        </p>
        <i className='fas fa-chevron-right'></i>
      </a>
    );
  } else {
    return (
      <div
        className={clsx(
          className,
          type === "dark" ? classes.darkRoot : classes.lightRoot,
          disable ? classes.disable : classes.noDisable
        )}
        onClick={disable ? () => { } : action}>
        <p className={type === "dark" ? classes.lightDes : classes.darkDes}>
          {content}
        </p>
        <i className='fas fa-chevron-right'></i>
      </div>
    );
  }

}
