import { HeaderStyles } from "./HeaderStyles";
import LogoImg from "../../assets/images/header-logo.png";
//import MenuImg from "../../assets/icons/icon_header_menu.png";
import { useLocation, useNavigate } from "react-router-dom";
//import SearchPanel from "./components/SearchPanel/SearchPanel";
import AppsIcon from '@mui/icons-material/Apps';
import { useEffect, useState } from "react";
import ActionInput from "components/ActionInput/ActionInput";
import { SEARCH_DIR_NAME } from "config/constant";

interface HeaderProps {
  mobileAction: () => void;
}

export default function Header({ mobileAction }: HeaderProps) {
  const classes = HeaderStyles();
  const navigate = useNavigate();
  const location: any = useLocation();
  //const [searchPanel, setSearchPanel] = useState(false);

  const [keyword, setKeyword] = useState<string>((location.state?.keyword && location.pathname === "/" + SEARCH_DIR_NAME) ? location.state.keyword : "");

  /* const handleToLanding = () => {
    navigate("/");
  }; */

  /* const handleSearchPanel = () => {
    setSearchPanel(true);
  };

  const searchPanelClose = () => {
    setSearchPanel(false);
  };

  const searchAction = (e: any) => {
  }; */

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.nativeEvent.isComposing || e.key !== 'Enter') return
    handleSubmit(e)
  }

  const handleSubmit = (
    e: React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent<HTMLInputElement>,
  ) => {

    if (location.pathname === "/" + SEARCH_DIR_NAME) {
      window.location.reload();
      navigate(SEARCH_DIR_NAME, {
        state: {
          keyword: keyword,
        }
      });
    } else {
      navigate(SEARCH_DIR_NAME, {
        state: {
          keyword: keyword,
        }
      });
    }

  }

  useEffect(() => {
    if (location.pathname !== "/" + SEARCH_DIR_NAME)
      setKeyword("");
  }, [location]);

  return (
    <>
      <div className={classes.root}>
        <div className={classes.menu}>
          {/* <i className='fas fa-bars' onClick={mobileAction}></i> */}
          {/* <i onClick={mobileAction}><img
            src={MenuImg}
            className={classes.menuImg}
          /></i> */}
          {/*
          <AppsIcon
            onClick={mobileAction}
            className={classes.menuImg}
            style={{ fontSize: "32px" }}
          />
          */}
          <a href="/" className={classes.menuAnchor}>
            <div
              className={classes.logoImg}
              //onClick={handleToLanding}
            />
          </a>
        </div>
        <div className={classes.searchRoot} /* onClick={handleSearchPanel} */>
          <div className={classes.searchIcon}>
            <i className='far fa-search'></i>
          </div>
          <ActionInput
            value={keyword}
            action={(e) => setKeyword(e)}
            onKeyDown={(e) => handleKeyDown(e)}
            placeholder='キーワードから探す'
            autoComplete="off"
            className={classes.inputArea}
          />
          {/* <div className={classes.searchInput}>キーワードから探す</div> */}
        </div>
      </div>
      {/* <SearchPanel
        show={searchPanel}
        close={searchPanelClose}
        input={searchAction}
      /> */}
    </>
  );
}
