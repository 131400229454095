import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formWrapper: {
      display: "flex",
      justifyContent: "center",
    },
    loaderWrapper: {
      width: "100%",
      height: "100%",
      overflowY: "scroll",
      padding: "16px",
      justifyContent: "center",
      alignItems: "center",
      fontFamily: "Noto Sans JP",
    },
    title: {
      fontStyle: "normal",
      fontFamily: "Noto Sans JP",
      fontWeight: 600,
      fontSize: "20px",
      lineHeight: "50px",
      color: "#55727e",
    },
    mainContainer: {
      padding: "18px 50px 0 50px",
      [theme.breakpoints.down(540)]: {
        padding: 0,
      },
    },
    btnContainer: {
      marginTop: "36px",
      display: "flex",
    },
    sendBtn: {
      width: "100% !important",
      "&:hover": {
        opacity: 0.7,
      },
    },
    loading: {
      opacity: 0.7,
    },
    form: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      marginBottom: "10px",
    },
    formTitle: {
      marginRight: "5px",
      color: "#000",
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "29px",
      textAlign: "center",
    },
    requiredMark: {
      color: "red",
      marginLeft: "4px",
    },
    message: {
      minWidth: "100px",
      margin: "15px 0 15px 0",
      fontWeight: 800,
      fontSize: "14px",
      lineHeight: "29px",
      textAlign: "left",
    },
    errorMessage: {
      color: "red",
    },
    resultMessage: {
      color: "green",
    },
    formInput: {
      "&:focus-within": {
        outline: "2px solid #6AC6C6",
      },
    },
  })
);
